import {
    calcEmotionsAvg,
    isEmotionHappy,
    isSad,
    isAngry,
    isDisgusted,
    isSurprised,
    isFearful,
    isNeutral,
    isEmotional,
    convertEmotions,
    convertCheckupEmotions,
    calcValenceTracker,
    calcValence,
    calcEnergyTracker,
    calcEnergy,
    calcStress,
    calcWellbeing,
    calcInterest,
    calcEngagement,
} from "solo-common"
import {
    ENGAGEMENT_HIGH,
    ENGAGEMENT_MEDIUM,
    INTEREST_HIGH,
    INTEREST_MEDIUM,
    STRESS_HIGH, STRESS_LOW,
    STRESS_MEDIUM,
    FACE_ID_MIN_DISTANCE,
    DETECTION_SCORE_THRESHOLD,
    HEAD_ANGLE_EMOTION_FILTER_ACTIVE
} from "../../api/remoteConfig";
import {LEVELS} from "../../constants";
import colors from "../../styles/colors";
import {findMatchingFaceIDLocationBased, faceIdMap, generateFaceID, filterFaceIdMap} from "solo-web-engine";
import {useSelector, useDispatch} from "react-redux";
import {addFaceId, updateFaceIdCount} from "../../redux/faceIdSlice";
import {headFacingCameraEmotionFilter} from "solo-web-engine/src/bioFeedback";
import {format} from "../../views/Dashboard/TableData/utils";
import store from "../../redux/store";
const getFaceId = (r) =>{

    const dispatch = store.dispatch
    let descriptor = r.descriptor
    //  console.log("r", r)
    const matchingFaceID = findMatchingFaceIDLocationBased(r.detection.box, FACE_ID_MIN_DISTANCE);
    console.log("matchingFaceID", matchingFaceID)
    let id;

    // Get the current state of faceIdMap and faceIdCount from the Redux store
    const faceIdCount = store.getState().faceId.faceIdCount;

    if (matchingFaceID) {
        // If a match is found, assign the same face ID to the current face
        id = matchingFaceID
    } else {
        // If no match is found, generate a new face ID and add it to the list of detected face IDs
        id = generateFaceID();
        dispatch(addFaceId(id))
    }
    faceIdMap.set(id, r.detection.box);

    if (!faceIdCount[id]) {
        dispatch(updateFaceIdCount({id, count: 1}))
    } else {
        dispatch(updateFaceIdCount({id, count: faceIdCount[id] + 1}))
    }
    return id;
}

export const getResults = (id, avg, filterEmotions) => {

   // console.log("getResults", {id, avg, filterEmotions})

    const valence = filterEmotions ? null : calcValenceTracker(avg)
    const energy = filterEmotions ? null : calcEnergyTracker(avg)
    const stress = filterEmotions ? null : calcStress(avg)
    const wellbeing = filterEmotions ? null : calcWellbeing(avg)
    const interest = filterEmotions ? null : calcInterest(avg)
    const engagement = filterEmotions ? null : calcEngagement(avg)

    const stressLevel = calcStressLevel(stress)
    const interestLevel = calcInterestLevel(interest)
    const engagementLevel = calcEngagementLevel(engagement)
    const wellbeingLevel = calcWellbeingLevel(wellbeing)

    return {
        valence,
        mood: valence,
        energy,
        stress,
        wellbeing,
        interest,
        engagement,
        id,
        stressLevel,
        interestLevel,
        engagementLevel,
        wellbeingLevel
    }
}

export const handleDetectionResult = (result, ts) => {
    const detections = []
    let emotions= {
        mood: null,
        energy: null,
        wellbeing: null,
        engagement: null,
        interest: null,
        stress: null,
        angry: null,
        disgusted: null,
        fearful: null,
        happy: null,
        neutral: null,
        sad: null,
        surprised: null,
    }

    if (result && result.length) {

        let i = 0
        let frameIds = []
        for (const r of result) {

            let widgets = {}
            let id = getFaceId(r);
            frameIds.push(id)
            let score = r.detection.score;
            let filterEmotions = r.toFilter;

            const {angry, disgusted, fearful, happy, neutral, sad, surprised} = r.expressions

            let thresholdEmotions = {
                happiness: filterEmotions ? null :happy,
                neutral: filterEmotions ? null :neutral,
                angry: filterEmotions ? null :angry,
                disgusted: filterEmotions ? null :disgusted,
                surprised: filterEmotions ? null :surprised,
                sad: filterEmotions ? null :sad,
                fearful: filterEmotions ? null :fearful
            }

            widgets = getResults(id, thresholdEmotions, filterEmotions)

            const detectionResult = {
                ...widgets,
                id,
                expressions: { angry, disgusted, fearful, happy, neutral, sad, surprised },
                detection: {
                    ...r.detection,
                    _imageDims: { ...r.detection._imageDims },
                    _box: { ...r.detection._box }
                },
                yawn: r.yawn,
                yawnRatio: r.yawnRatio,
                headPose: r.headAngles,
                frown: r.frown,
                closedLeftEye: r.closedLeftEye,
                openLeftEye: r.openLeftEye,
                closedRightEye: r.closedRightEye,
                openRightEye: r.openRightEye,
                closedEyes: r.closedEyes,
                openEyes: r.openEyes,
                score: score,
                time: ts,
                faceBlendshapes: r.faceBlendshapes,
                eyesDirection: r.eyesDirection,
                headAngles: r.angle,
                toFilter: r.toFilter,
                filterReason: r.filterReason
            }


            detections.push(detectionResult)
        }

        filterFaceIdMap(frameIds)
        emotions = calcResultAvg(detections)
    }

    //console.log("frame " + ts, store.getState().faceId.faceIdCount)


    return  {
        currentTime: ts,
        result: detections,
        emotions: emotions
    }
}

const calcResultAvg = (result = []) => {
    const arrayAvg = (arr) => {
        if (!arr.length) {
            return 0;
        }
        return arr.reduce((p, c) => p + c, 0) / arr.length
    }

    let avgArr = {
        neutral: [],
        happiness: [],
        sad: [],
        angry: [],
        fearful: [],
        disgusted: [],
        surprised: [],
        energy: [],
        engagement: [],
        interest: [],
        stress: [],
        valence: [],
        wellbeing: [],
    }

    result.map((item) => {
        avgArr.neutral.push(item.expressions.neutral)
        avgArr.happiness.push(item.expressions.happy)
        avgArr.sad.push(item.expressions.sad)
        avgArr.angry.push(item.expressions.angry)
        avgArr.fearful.push(item.expressions.fearful)
        avgArr.disgusted.push(item.expressions.disgusted)
        avgArr.surprised.push(item.expressions.surprised)
        avgArr.energy.push(item.energy)
        avgArr.engagement.push(item.engagement)
        avgArr.interest.push(item.interest)
        avgArr.stress.push(item.stress)
        avgArr.valence.push(item.valence)
        avgArr.wellbeing.push(item.wellbeing)
    })

    let avg = {
        neutral: arrayAvg(avgArr.neutral),
        happy: arrayAvg(avgArr.happiness),
        sad: arrayAvg(avgArr.sad),
        angry: arrayAvg(avgArr.angry),
        fearful: arrayAvg(avgArr.fearful),
        disgusted: arrayAvg(avgArr.disgusted),
        surprised: arrayAvg(avgArr.surprised),
        energy: arrayAvg(avgArr.energy),
        engagement: arrayAvg(avgArr.engagement),
        interest: arrayAvg(avgArr.interest),
        stress: arrayAvg(avgArr.stress),
        valence: arrayAvg(avgArr.valence),
        wellbeing: arrayAvg(avgArr.wellbeing),
    }

    return avg;
}


export const calcInterestLevel = (interest) => {
    let level
    if (interest > INTEREST_HIGH) {
        level = LEVELS.LIKE
    } else if (interest < INTEREST_HIGH && interest > INTEREST_MEDIUM) {
        level = LEVELS.NEUTRAL
    } else if (interest < INTEREST_MEDIUM) {
        level = LEVELS.DISLIKE
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return { value: level, color }
}

export const calcEngagementLevel = (engagement) => {
    let level
    if (engagement > ENGAGEMENT_HIGH) {
        level = LEVELS.HIGH_ENGAGEMENT
    } else if (engagement < ENGAGEMENT_HIGH && engagement > ENGAGEMENT_MEDIUM) {
        level = LEVELS.MEDIUM
    } else if (engagement < ENGAGEMENT_MEDIUM) {
        level = LEVELS.LOW_ENGAGEMENT
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return { value: level, color }
}

export const normalizeValue = (value) => {
    if(value === null || value === undefined){
        return null
    }
    let res
    if (value < 0) {
        res = 0
    } else if (value > 1) {
        res = 1
    } else {
        res = value
    }
    return Math.round(res * 100)
}

export const calcWellbeingLevel = (wellbeing) => {
    let color, value = Math.round(normalizeValue(wellbeing))
    if (wellbeing >= 0.7) {
        color = colors.green
    } else if (wellbeing < 0.7 && wellbeing > 0.3) {
        color = colors.yellowish
    } else {
        color = colors.tomato
    }
    return { value, color }
}

export const getLevelColor = (level) => {
    switch (level) {
        case LEVELS.HIGH_STRESS:
        case LEVELS.DISLIKE:
        case LEVELS.LOW_ENGAGEMENT:
            return colors.tomato
        case LEVELS.MEDIUM:
        case LEVELS.NEUTRAL:
            return colors.yellowish
        case LEVELS.LOW_STRESS:
        case LEVELS.LIKE:
        case LEVELS.HIGH_ENGAGEMENT:
            return colors.green
        default:
            return colors.warm_grey
    }
}

export const calcStressLevel = (stress) => {
    let level
    if (stress > STRESS_HIGH) {
        level = LEVELS.HIGH_STRESS
    } else if (stress < STRESS_HIGH && stress > STRESS_MEDIUM) {
        level = LEVELS.MEDIUM
    } else if (stress < STRESS_MEDIUM && stress > STRESS_LOW) {
        level = LEVELS.LOW_STRESS
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return { value: level, color }
}

export function calculateEyebrowDirection(browsOuterUp, browsDown) {

    // Normalize the values to be within the range [-1, 1]
    let normalizedUp = browsOuterUp ;
    let normalizedDown = browsDown;

    // Calculate the direction
    let direction = normalizedUp - normalizedDown;

    // Scale the result to be within [-100, 100]
    return direction * 100;
}

export {
    calcEmotionsAvg,
    isEmotionHappy,
    isSad,
    isAngry,
    isDisgusted,
    isSurprised,
    isFearful,
    isNeutral,
    isEmotional,
    convertEmotions,
    convertCheckupEmotions,
    calcValenceTracker,
    calcValence,
    calcEnergyTracker,
    calcEnergy,
    calcStress,
    calcWellbeing,
    calcInterest,
    calcEngagement
}
