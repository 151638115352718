import React, { Component } from 'react';
import PropTypes from 'prop-types';

class VideoStream extends Component {
    constructor(props) {
        super(props);
        this.stream = null;
        this.mediaRecorder = null;
        this.state = {
            recording: false,
            recordedChunks: [],
            countdown: 3, // Initial countdown value
            counting: false
        };
    }

    componentDidMount() {
        this.streamVideo();
    }

    componentWillUnmount = () => {
        this.stopRecording();
        this.stream && this.stream.getTracks().forEach(function (track) {
            track.stop();
        });
        // this.props.streamStopped()
    }

    streamVideo = () => {
        const { width, height } = this.props;
        const constraints = {
            audio: false, video: {
                width: { min: 640, ideal: 1920 },
                height: { min: 480, ideal: 1080 },
                frameRate: { min: 30 },
                advanced: [
                    { width: 1920, height: 1080 },
                    { aspectRatio: 4 / 3 },
                    { frameRate: { min: 50 } },
                    { frameRate: { min: 40 } }
                ]
            }
        };
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((mediaStream) => {
                const video = document.querySelector("#videoElement");
                this.stream = mediaStream;

                let videoTracks = mediaStream.getVideoTracks();
                console.log("got media stream", mediaStream, videoTracks[0].getCapabilities(), videoTracks[0].getSettings(), videoTracks[0].getConstraints());
                video.srcObject = mediaStream;
                video.onloadedmetadata = (e) => {
                    video.play();
                    if (this.props.countDown) {
                        this.props.warmup(video)
                        this.startCountdown(mediaStream, video);
                    } else {
                        if (this.props.shouldRecord) {
                            this.startRecording(mediaStream);
                        }
                        this.onStreamSuccess(video);
                    }
                };
            })
            .catch((err) => {
                console.log(err);
            });
    }

    startCountdown = (mediaStream, video) => {
        this.setState({ countdown: 5, counting: true });

        // Start "warm-up" recording but do not save the chunks
        this.startRecording(mediaStream, { warmUp: true });

        const countdownInterval = setInterval(() => {
            this.setState(prevState => {
                if (prevState.countdown === 1) {
                    clearInterval(countdownInterval);
                    this.setState({ counting: false });

                    // Stop the warm-up recording
                    this.stopRecording();

                    // Start actual recording, now saving chunks
                    if (this.props.shouldRecord) {
                        this.startRecording(mediaStream, { warmUp: false });
                    }

                    // Trigger success callback
                    this.onStreamSuccess(video);
                }
                return { countdown: prevState.countdown - 1 };
            });
        }, 1000);
    };

    startRecording = async (stream, { warmUp = false }) => {
        this.mediaRecorder = new MediaRecorder(stream);

        // If it's a warm-up recording, do not save the chunks
        if (!warmUp) {
            this.mediaRecorder.ondataavailable = this.handleDataAvailable;
        } else {
            this.mediaRecorder.ondataavailable = () => {
                // Simply ignore the recorded data
            };
        }

        this.mediaRecorder.start();
        console.log(`Recording started (${warmUp ? "warm-up" : "actual"})`, this.mediaRecorder.state, this.mediaRecorder.stream.active);

        this.setState({ recording: true });
    };

// Stop recording function remains the same
    stopRecording = () => {
        if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
            this.mediaRecorder.stop();
            this.setState({ recording: false });
        }
    };

    handleDataAvailable = (e) => {
        const { recordedChunks } = this.state;
        const { onRecordUpdate } = this.props;
      //  console.log("data available", e.data.size);
        if (e.data.size > 0) {
          //  console.log("data available", e.data);
            recordedChunks.push(e.data);
            onRecordUpdate(e.data);
            this.setState({ recordedChunks });
        }
    };

    onStreamSuccess = async (input) => {
       // console.log("stream success", input);
        const { streamSuccessHandler, updateInput } = this.props;
        await updateInput(input);
        streamSuccessHandler && streamSuccessHandler();
    }

    renderCountdown = () => {
        const { countdown, counting } = this.state;
        if (countdown > 0 && counting) {
            return (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    fontSize: '48px',
                    fontWeight: 'bold',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '20px',
                    borderRadius: '10px'
                }}>
                    {countdown}
                </div>
            );
        }
        return null;
    }

    render() {
        const { maxHeight } = this.props;
        return (
           <>
               <video
                   style={{ maxWidth: '100%', maxHeight: maxHeight }}
                   autoPlay={true}
                   id="videoElement"
                   ref={this.props.innerRef}
               />
               {this.props.countDown && this.renderCountdown()}
           </>
        );
    }
}

VideoStream.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    streamSuccessHandler: PropTypes.func,
    onRecordUpdate: PropTypes.func,
    shouldRecord: PropTypes.bool,
    countDown: PropTypes.bool, // New prop for countdown
    updateInput: PropTypes.func,
    innerRef: PropTypes.object,
    maxHeight: PropTypes.string
};

VideoStream.defaultProps = {
    width: 1280,
    height: 720
};

export default VideoStream;
