import React, {Component, useRef, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTable, useSortBy, useGlobalFilter, useAsyncDebounce, useExpanded} from 'react-table'
import memoize from 'memoize-one';import _, {get, findIndex} from "lodash";
import styled from "styled-components";
import {getAsset} from "../../utils";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import moment from "moment"

import {getString, translations} from "../../utils/translations";
import {useSelector, useDispatch} from 'react-redux';
import Input from "../../components/Input";

import {deleteGroupAdminData, deleteGroupData, getGroupAdminsData, updateGroupData} from "../../redux/groups/api";


import Minutes from '../Dashboard/TableData/Minutes';
import Icon from '../Dashboard/TableData/Icon';
import Name from '../Dashboard/TableData/Name';

import DateCell from '../Dashboard/TableData/DateCell';
import {Fragment} from 'react';

import Button from "../../components/Button";
import {HOUSE_TYPES} from "../../constants";

import CreateAdminPopup  from "./CreateAdminPopup";
import {OverTimeButton} from "../../components/solo-styled-components";
import AdminList from "./AdminsList";
import CreateGroupPopup from "./CreateGroupPopup";
import Popup from "../../components/Popup";


function GlobalFilter({
                          preGlobalFilteredRows,
                          globalFilter,
                          setGlobalFilter,
                          currPlaceholder
                      }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
    return (
        <div style={{display: "flex"}}>
            <Input
                type={"text"}
                value={value}
                onChange={(value) => {
                    setValue(value);
                    onChange(value);
                }}
                placeholder={getString(`${currPlaceholder}`)}
                placeholderIcon={"icons/search.svg"}
                style={{width: 200}}
            />
        </div>
    )
}


const TableData = ({
                       data,
                       headerTitle,
                       currPlaceholder,
                       rowsIcon,
                       colData,
                       isDesc,
                       sortBy,
                   }) => {

    const [tableRows, setTableRows] = useState([])
    const [adminPopupOpen, setAdminPopupOpen] = useState(false)
    const [createGroupPopupOpen, setCreateGroupPopupOpen] = useState(false)
    const [activateGroupPopup, setActivateGroupPopup] = useState(false)
    const [deleteGroupPopup, setDeleteGroupPopup] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(null)

    const dispatch = useDispatch()
    const groupAdmins = useSelector(state => state.groups.groupsAdmins)


    const onManageUsers = (row, value) => {
        console.log("onManageUsers", row, value)
        const {id} = value;
        row.getToggleRowExpandedProps({}).onClick()
        if(!row.isExpanded){
            dispatch(getGroupAdminsData(id))
            setSelectedGroup(id)
        }else {
            setSelectedGroup(null)
        }
    }



    let COLUMNS = [
        {
            id: "NAME",
            Header: getString(`group_name`),
            /*Cell: ({row, value}) => {
                return (
                    <Name value={value} />
                )
            },*/
            accessor: (originalRow) => {
                //  console.log("date accessor", originalRow, get(originalRow, "date", 0))
                return get(originalRow, "name", 0)
            },
        },
        {
            id: "AI_UNITS",
            Header: getString("ai_units"),
            accessor: (originalRow, rowIndex) => {
                const aiUnits = get(originalRow, "aiUnits", 0)
                return aiUnits
            },
            Cell: ({row, value}) => {
                return (
                    <Minutes value={value} item={row.original}/>
                )
            }
        },
        {
            id: "UNITS_LIMIT",
            Header: getString("ai_units_limit"),
            accessor: (originalRow, rowIndex) => {
                const aiUnitsLimit = get(originalRow, "AIUnitsLimits[0].unitsLimit", null)
                return aiUnitsLimit
            },
            Cell: ({row, value}) => {
                return (
                    <Minutes value={value} item={row.original}/>
                )
            }
        },
        {
            id: "ACTIONS",
            Header: "",
            accessor: (originalRow) => {
                return originalRow
            },
            Cell: ({row, value}) => {

                const {active, admins, id} = value;

                return (
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Button
                            style={{...buttonStyle, backgroundColor: colors.blue}}
                            onClick={()=>{
                                onManageUsers(row, value)

                            }}>
                            <span>{getString("manage_users")}</span>
                        </Button>

                        <Button
                            style={{...buttonStyle, backgroundColor: colors.blue}}
                            onClick={()=>{
                              //  onManageUsers(row, value)
                                setSelectedGroup(value)
                                setCreateGroupPopupOpen(true)
                            }}>
                            <span>{getString("edit_group")}</span>
                        </Button>

                        <Button
                            style={{...buttonStyle, backgroundColor: colors.lipstick}}
                            onClick={()=>{
                                setActivateGroupPopup(value)}}>
                            <span>{active ? getString("deactivate"): getString("activate")}</span>
                        </Button>

                        <Button
                            style={{...buttonStyle, backgroundColor: colors.black}}
                            onClick={()=>{setDeleteGroupPopup(value)}}>
                            <span>{getString("remove")}</span>
                        </Button>


                    </div>
                )
            }
        }
    ];

    const columns = React.useMemo(
        () => COLUMNS, []
    )



    const tableData = React.useMemo(
        () => data,
        [data]
    )

    const tableInstance = useTable({
        columns, data: tableData, initialState:
            sortBy ? {
                sortBy: [
                    {
                        id: sortBy,
                        desc: isDesc
                    }
                ]
            } : {}
    }, useGlobalFilter, useSortBy, useExpanded)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state
    } = tableInstance

    useEffect(() => {
        setTableRows(rows)
    }, [rows])

    const TableHeaderWrapper = () => {
        return (
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}
                >
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                                textAlign: 'center',
                                border: 'none',
                                color: column.isSorted ? colors.lipstick : '#909090',
                                fontWeight: column.isSorted ? "800" : "700",
                                fontFamily: fonts.OpenSans,
                                fontSize: 13,
                                letterSpacing: 1.12,
                                cursor: "pointer"
                            }}
                        >
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <div>
                                {
                                    column.id === 'ACTIONS' ? null : column.isSorted
                                        ? column.isSortedDesc
                                            ? <img src={getAsset("icons/arrow_blue.svg")}/>
                                            : <img style={{transform: "rotate(180deg)"}}
                                                   src={getAsset("icons/arrow_blue.svg")}/>
                                        : <img src={getAsset("icons/arrow_blue.svg")}/>
                                }
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
        )
    }

    const getCellStyle = (cell) => {
        let cellStyle = {}
        return cellStyle
    }


    const onRowClicked = (row) => {
        let rowId = get(row, "original.id", "");
        console.log("onRowClicked", rowId)
    }

    const TableBodyWrapper = () => {
        // console.log("TableBodyWrapper", rows)
        return (
            <tbody {...getTableBodyProps()}>
            <tr className="tbl-row" style={{
                backgroundColor: colors.lipstick,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                width: "100%", paddingLeft :15, cursor: "pointer"
            }} onClick={()=>{setCreateGroupPopupOpen(true)}}>
                <td style={{color: "#fff", fontSize: 15, fontStyle: fonts.OpenSans, fontWeight: 700}}>
                    {getString("create_group_button")}
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
            </tr>

            {rows.map((row, i) => {
                let rowId = get(row, "original.id", "");


                prepareRow(row)
                return (
                    <Fragment key={i}>
                        <tr className="tbl-row" {...row.getRowProps()}
                            onClick={() => onRowClicked(row)}
                            style={{padding: 15}}>
                            {row.cells.map(cell => {
                                const cellStyle = getCellStyle(cell)
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={cellStyle}
                                        onClick={() => {
                                         //   console.log("cell clicked", cell)
                                            if(_.get(cell, "column.id") === "ACTIONS"){
                                                return
                                            }
                                            onManageUsers(row, cell.row.original)
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                        {row.isExpanded && renderSubRow(row.id, row.original, row)}
                    </Fragment>
                )
            })}
            </tbody>
        )
    }

    const renderSubRow = (rowId, originalRow, row) => {
       // console.log("renderSubRow", {groupAdmins, rowId, originalRow, row})
        return <tr style={{
            height: 300,
            position: 'relative'
        }}>
            <td>
                <SubRowContainer>


                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "100%",
                        padding: 20
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "100%"
                        }}>
                            <div>{getString("manage_users")}</div>
                            <OverTimeButton style={{height: 20, width: 50, marginLeft: 10, fontSize: 13, backgroundColor: colors.lipstick}} onClick={() => {
                                setAdminPopupOpen(true)
                            }}>{getString("add")}</OverTimeButton>
                        </div>

                        <AdminList groupAdmins={groupAdmins} originalRow={originalRow} dispatch={dispatch}/>


                    </div>


                </SubRowContainer>
            </td>
        </tr>
    }

    return (
        <Styles>
            <Header>
                <div style={{display: "flex", alignItems: "baseline"}}>
                    <Title>{getString(`groups`)}</Title>
                </div>

                <div style={{display: "flex", flexDirection: 'row'}}>
                    <div>
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            currPlaceholder={getString(`search_group`)}
                        />
                    </div>
                </div>
            </Header>
            <table {...getTableProps()} style={{
                borderCollapse: "separate",
                borderSpacing: "0 1em"
            }}>
                <TableHeaderWrapper/>
                <TableBodyWrapper/>
            </table>


            {adminPopupOpen && (<CreateAdminPopup
                isOpen={adminPopupOpen}
                closeHandler={() => setAdminPopupOpen(false)}
                groupId={selectedGroup}
            />)}

            {createGroupPopupOpen && (<CreateGroupPopup
                isOpen={createGroupPopupOpen}
                groupData={selectedGroup}
                closeHandler={() => {
                    setCreateGroupPopupOpen(false)
                    setSelectedGroup(null)
                }}
            />)}




            {activateGroupPopup ? <Popup
                closeOnDocumentClick={true}
                isOpen={true}
                closeHandler={()=>setActivateGroupPopup(false)}
                title={getString(activateGroupPopup.active ? "deactivate_group_popup_title" : "activate_group_popup_title")}
                description={getString(activateGroupPopup.active ? "deactivate_group_popup_description" : "activate_group_popup_description")}
                footerButtons={[
                    {
                        label: getString(activateGroupPopup.active ? "deactivate" : "activate"),
                        onPress: ()=>{
                            let unitsLimit = _.get(activateGroupPopup,"AIUnitsLimits[0].unitsLimit");
                            dispatch(updateGroupData({...activateGroupPopup, active: !activateGroupPopup.active, unitsLimit: unitsLimit}))
                            setActivateGroupPopup(false)
                        },
                        background: colors.lipstick
                    },
                    {
                        label: getString("keep"),
                        onPress: ()=>{setActivateGroupPopup(false)},
                        background: colors.green
                    }
                ]}
            /> : null}

            {deleteGroupPopup ? <Popup
                closeOnDocumentClick={true}
                isOpen={true}
                closeHandler={()=>setDeleteGroupPopup(false)}
                title={getString("delete_group_popup_title")}
                description={getString("delete_group_popup_description")}
                footerButtons={
                    [
                        {
                            label: getString("delete"),
                            onPress: ()=>{
                                console.log("delete")
                                dispatch(deleteGroupData(deleteGroupPopup.id))
                                setDeleteGroupPopup(false)
                            },
                            background: colors.lipstick
                        },
                        {
                            label: getString("keep"),
                            onPress: ()=>{setDeleteGroupPopup(false)},
                            background: colors.green
                        }
                    ]
                }
            /> : null}


        </Styles>
    )
}

export default TableData

TableData.propTypes = {
    data: PropTypes.array
};


const BlackVIcon = styled.img`
    width: 17px;
    height: 17px;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
`

const Header = styled.div`
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            `

const SubRowContainer = styled.div`
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: white;
            border-radius: 8.44px;
            display: flex;
            justify-content: center;
            `

const VideoNameContainer = styled.div`
            display: flex;
            flex-direction: row;
            margin-bottom: 4px;
            `

const VideoName = styled.div`
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            align-items: center;

            color: #2E384D;
            `

const DeleteVideo = styled.div`
            margin-top: 5px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            justify-content: center;
            color: #CC1D6C;
            cursor: pointer;
            text-transform: uppercase;
            `

const Title = styled.div`
            font-family: Rubik;
            font-style: normal;
            font-weight: 300;
            font-size: 1.75em;
            line-height: 1.25em;
            /* identical to box height, or 114% */

            color: #2E384D;
            `

const Count = styled.div`
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375em;
            line-height: 1.375em;
            /* identical to box height, or 147% */
            color: #8798AD;
            margin-left: 0.625em;
            `

const CellContainer = styled.div`
            display: flex;
            align-items: center;
            margin-left: ${props => props.marginLeft};
            justify-content: ${props => props.contentPositioning};

            @media print{
                display: initial;
    }
            `

const CellContent = styled.span`
            //   font-family: Rubik;
            //   font-style: normal;
            //   font-weight: 500;
            //   font-size: 15px;
            //   line-height: 18px;
            //   margin-left: 22px;

            color: #2E384D;

            font-family: Open Sans;
            font-style: normal;
            font-weight: 800;
            font-size: 0.9375em;
            line-height: 1.25em;
            display: flex;
            align-items: center;
            margin-left: 0.9375em;

            @media print{
                width: 55px;
            height: 55px;
            display: block;
            margin-left: 40px;
            margin-top: -30px;
            margin-right: -38px;
  }
            `

const NextScreenArrow = styled.img`
            margin-right: 1.25em;
            margin-left: -1.5625em;
            vertical-align: middle;
            `


const Styles = styled.div`
            margin-top: 2.75em;

            /* This is required to make the table full-width */
            display: block;
            max-width: 100%;

            /* This will make the table scrollable when it gets too small */

            .tableWrap {
                display: block;
            max-width: 100%;
            //overflow-x: scroll;
            // overflow-y: hidden;
            border-bottom: 1px solid black;
  }

            table {
                /* Make sure the inner table is always as wide as needed */
                width: 100%;
            border-spacing: 0 18px;
            margin-top: 1.4375em;
            //max-height: 6.25em;
            position: relative;
            //   border-collapse: collapse;
            //border-collapse: collapse !important;
            tbody {
                tr {
                background: #fff;
            border-radius: 10px;
            border: 1px solid rgba(46, 91, 255, 0.08);
            padding: 1.25em;
      }
    }

            th {
                //position: sticky;
                //top: 0; /* Don't forget this, required for the stickiness */
                background - color: #f4f6fc;
            z-index: 50;
    }

            th,
            td {
                margin: 0;
            padding: 0.5rem;

            /* The secret sauce */
            /* Each cell should grow equally */
            width: 1%;
            /* But "collapsed" cells should be as small as possible */

            &.collapse {
                width: 0.0000000001%;
    }

            :last-child {
                border - right: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
      }

            :first-child {
                border - top - left - radius: 10px;
            border-bottom-left-radius: 10px;
      }
    }
  }

            .pagination {
                padding: 0.5rem;
  }

            .tbl-row {
                transition: box-shadow .3s;
            cursor: pointer;
  }
            .tbl-row:hover {
                box - shadow: 0 0 0.6875em rgba(33,33,33,.2);
  }

            @media print {
    .tbl - head {
                display: table-header-group;
    }
            .tbl-body {
                display: table-row-group;
    }
            .tbl-row {
                display: inline-block;
            width: 100%;
            page-break-inside: avoid;
            padding: 10px !important;
            position: relative;
            right: 0.625em;
    }
  }
            `

//get array of hh:mm:ss:xx strings from duration and fps
const getTimeArray = (duration, fps) => {
    let timeArray = [];
    let time = 0;
    let fpsDuration = 1 / fps;
    while (time < duration) {
        timeArray.push(formatTime(time));
        time += fpsDuration;
    }
    return timeArray;
}

// format seconds to hh:mm:ss.xxx string
const formatTime = (seconds) => {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);
    let x = Math.floor((seconds % 1) * 100);
    return `${h}:${m}:${s}.${x}`;
}

//convert hh:mm:ss.xxx string to seconds
const stringToSeconds = (string) => {
    let parts = string.split('.');
    let milliseconds = parts[parts.length - 1]
    parts = parts[0].split(":")
    let seconds = 0;
    seconds += parseInt(parts[0]) * 3600;
    seconds += parseInt(parts[1]) * 60;
    seconds += parseInt(parts[2]);
    seconds += parseInt(milliseconds) / 100;
    return seconds;
}

const buttonStyle = {
    marginLeft: 11,
    background: colors.lipstick,
    paddingLeft: 15,
    paddingRight: 15,
    height: 35,
    borderRadius: 10,
    textAlign: 'left',
    fontWeight: 'bold',
}
