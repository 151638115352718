import React, {Component} from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import DetectionSquare from "./DetectionSquare"
import {getString, translations} from "../utils/translations"
import {OVER_TIME_FACE_DIRECTIONS, OVER_TIME_FACIAL_MUSCLES_BUTTONS, OVER_TIME_MODULES_BUTTONS} from "../constants"

import _, {findIndex} from "lodash";
import OverTimeChart from './Charts/OverTime';
import colors from "../styles/colors"
import VideoFaceDetector from "./VideoFaceDetector";
import VideoStream from "./VideoStream";

import {format} from "../views/Dashboard/TableData/utils"
import {setVideoTracker} from "../redux/videos"

import {connect} from "react-redux";

import {
    normalizeValue, handleDetectionResult, calculateEyebrowDirection
} from "../utils/bioFeedback"
import {detect, getDefaultOptions, landmarksWrapper, clearFaceIdMap, faceIdMap} from "solo-web-engine";
import {
    BLINK_FREQUENCY_DURATION_SECONDS,
    BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS,
    FACE_CERTAINTY_FRAMES, HEAD_ANGLE_EMOTION_FILTER_ACTIVE
} from "../api/remoteConfig";
import {
    calculateFrequencyPerMinute,
    detectBlink,
    headFacingCameraEmotionFilter,
    videoDimensions
} from "solo-web-engine/src/bioFeedback";
import {addFaceId, resetFaceId, selectId, updateFaceIdCount} from "../redux/faceIdSlice";
import IdsButtons from "./Charts/OverTime/IdButtons";
import SoloOverTimeChart from "../views/Dashboard/SoloOverTimeChart";
import {getAsset} from "../utils";


class Video extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageWidth: 0,
            imageHeight: 0,
            detectionSquareTop: 0,
            detectionSquareLeft: 0,
            currentTime: 0,
            seconds: 0,
            noResultCount: 0,
            detectionsArray: [],
            input: null,
            overtime: [],
            previewOverTime: [],
            selectedButtons: [OVER_TIME_MODULES_BUTTONS.MOOD],
            videoSrc: null,
            videoPlaying: false,
            videoEnded: false,
            options: this.getOptions(props),
            backgroundAnalyzing: false,
            backgroundProcessingPercentage: 0,
            showFaceLandmarks: false,
            overtimeById: {},
            recordedVideoChunks: [],
            recordingReady: false,
            previewTracker: [],
            streamReady: false,
            countDown: 3,
            countingDown: false,
            warmupOffset: null
        }

        // this.componentTimeOut = null
        this.videoElement = React.createRef()
        this.shouldDetectTimeOut = null
        this.detectTimeOut = null
        this._isMounted = false;
        this.countDownInterval = null;

        this.videoRef = React.createRef();
        this.lastUpdate = 0;
        this.timer = null;
    }

    getOptions = (props) => {
        return getDefaultOptions()
    }

    componentDidMount() {
        const {videoUrl, videoFile, setAnalyzing, previewMode} = this.props
        let videoSrc = videoFile ? URL.createObjectURL(videoFile) : videoUrl
        this.setState({videoSrc})
        this.setState({videoPlaying: true})
        this._isMounted = true;
        setAnalyzing && setAnalyzing(true)
        if(previewMode){
            this.setState({showFaceLandmarks: true})
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {input} = this.state
        const {setAnalyzing, previewMode, videoAnalyzeForLiveStream} = this.props

        const video = document.getElementById('vid');

        if (video) {
            if (!input) {
                this.setState({input: video})
                this.currentDimensions()
            }
            video.onloadeddata = (event) => {
                console.log("onloadeddata")
                video.play()
            };
            video.onplay = (event) => {
                console.log("onplay")
                !previewMode && this.detectEmotions()
                if (this.state.videoEnded) {
                    this.setState({videoEnded: false})
                    !previewMode && setAnalyzing && setAnalyzing(false)
                }
                if(previewMode){
                    this.startSyncTimer()
                }

            };
            video.onpause = (event) => {
                if (!previewMode) {
                    clearTimeout(this.shouldDetectTimeOut)
                    clearTimeout(this.detectTimeOut)
                }
                this.setState({videoPlaying: false})
                console.log("onpause")
                this.stopSyncTimer()
            };
            video.onended = (event) => {
                if (!previewMode) {
                    clearTimeout(this.shouldDetectTimeOut)
                    clearTimeout(this.detectTimeOut)
                }
                this.setState({videoEnded: true, videoPlaying: false})
                !previewMode && setAnalyzing && setAnalyzing(false)
                console.log("onended")
                this.stopSyncTimer()
            };
            video.ontimeupdate = (event) => {
                if (previewMode) {
                    this.seekToDataAtTime(video.currentTime);
                }
            };
        }

        if (!videoAnalyzeForLiveStream && prevProps.videoAnalyzeForLiveStream) {
            clearTimeout(this.shouldDetectTimeOut)
            clearTimeout(this.detectTimeOut)
        }

        if (previewMode && !prevProps.previewMode) {
            console.log("assignOverTimeData", this.state.tracker)
            this.assignOverTimeData(this.props.tracker)
            if(video){
                video.play()
            }

            this.setState({showFaceLandmarks: true})
        }
    }

    startSyncTimer = () => {
        this.stopSyncTimer(); // Ensure any existing timer is cleared
        this.timer = setInterval(() => {
            if (this.props.previewMode) {
                const currentTime = this.videoElement.current.currentTime;
                this.seekToDataAtTime(currentTime);
            }
        }, 200); // Polling interval set to 200ms
    }

    stopSyncTimer = () => {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }


    componentWillUnmount() {
        console.log("componentWillUnmount")

        clearTimeout(this.shouldDetectTimeOut)
        clearTimeout(this.detectTimeOut)

        this._isMounted = false;
        const videoElement = this.videoElement.current;
        videoElement.removeEventListener('play', this.startSyncTimer);
        videoElement.removeEventListener('pause', this.stopSyncTimer);
        videoElement.removeEventListener('ended', this.stopSyncTimer);
        this.stopSyncTimer();
        //clearTimeout(this.componentTimeOut);
        clearFaceIdMap()
        this.props.resetFaceId()
    }

    onRecordingComplete = (recordedChunks) => {
        console.log("playing recorded video", this.state.recordedVideoChunks)
        const blob = new Blob(recordedChunks, {type: 'video/webm'});
        const url = URL.createObjectURL(blob);
        this.setState({videoSrc: url})
    }

    seekToDataAtTime = (time) => {
        this.alterOvertimeData(time)
        this.setDetectionArrayByTime(time)
    }

    assignOverTimeData = (tracker) => {
     let state = {
         overtime: [],
         overtimeById: {}
     }


        tracker.forEach((item, index) => {
            //   console.log("assignOverTimeData", item)
            state = this.pushToOverTime(item.result, item.currentTime, state)

        })
        //console.log("assignOverTimeData", state)
        this.setState(state)


    }

    alterOvertimeData(currentTime) {
        const filteredOvertime = this.state.overtime.filter(item => item.timeSpent <= currentTime * 1000);
        this.setState({previewOverTime: filteredOvertime});
    }

    setDetectionArrayByTime = (time) => {
    //    console.log("setDetection frame time", time)
        const {previewTracker, showFaceLandmarks} = this.state

        let detectionArray = previewTracker.filter(d => d.currentTime <= time && time - d.currentTime <= 0.2);
        // get last item from detectionArray (colsest to frame time)

        let timeFromLastFrame = _.last(previewTracker).currentTime - time
        let validLastFrame = _.get(_.last(previewTracker),"emotions.mood") !== null

        if (detectionArray.length > 0) {
            let sorted = detectionArray.sort((a, b) => b.currentTime - a.currentTime)
            detectionArray = sorted[0]
            this.setState({detectionsArray: detectionArray.result})
           // console.log("setDetection result time", {videoTime: time, trackerTime: detectionArray.currentTime}, detectionArray)
            if(showFaceLandmarks){
               // console.log("render blendshapes", Date.now(), detectionArray.currentTime)
                landmarksWrapper.drawResults(detectionArray.faceMeshResult, document.getElementById('canvas-overlay'), document.getElementById('vid'))
            }else {
                landmarksWrapper.clearResults()
            }
        } else {
            if(timeFromLastFrame < 0.2 && validLastFrame){
              //  console.log("timeFromLastFrame", {videoTime: time, trackerTime: _.last(previewTracker).currentTime, timeFromLastFrame}, _.last(previewTracker))
                this.setState({detectionsArray: []})
            }
        }

    }

    currentDimensions = () => {
        if (this.videoElement.current) {
            this.setState({
                imageWidth: this.videoElement.current.offsetWidth,
                imageHeight: this.videoElement.current.offsetHeight,
                detectionSquareTop: this.videoElement.current.offsetTop,
                detectionSquareLeft: this.videoElement.current.offsetLeft
            })
        }
    }

    shouldDetectEmotions = () => {
        const {videoPlaying} = this.state
        const {previewMode} = this.props
        if (!previewMode && videoPlaying) {
            return true
        }
        return false
    }
    detectEmotions = async () => {

        if (!this._isMounted) {
            return;
        }

        const {detectionInterval} = this.props
        const {input, options, countingDown, warmupOffset} = this.state

        try {

            let currentTime = this.videoElement.current && this.videoElement.current.currentTime
           // console.log("detectEmotions before", {countingDown,currentTime, warmupOffset})
            if(warmupOffset){
                currentTime = currentTime - warmupOffset
            }

            const {detectionsWithExpressions, faceMeshResult} = await detect(input, {
                emotionsModelActive: true,
                handsModelActive: true,
                showFaceLandmarks: {
                    show: this.state.showFaceLandmarks,
                    canvas: document.getElementById('canvas-overlay')
                },
                emotionsModelOptions: options,
                returnOriginalFacemesh: true,
                sizeFilter: false

            })

           // console.log("detectEmotions", detectionsWithExpressions, faceMeshResult)


            //let afterDetectEmotions = this.videoElement.current && this.videoElement.current.currentTime

            //  console.log("RESULT", result)

            /* if (result && result.length === 0 && beforeDetectEmotions === 0) {//&& afterDetectEmotions === 0
                 return this.shouldDetectTimeOut = setTimeout(() => this.detectEmotions(), 50)
             }*/
           // currentTime = this.videoElement.current && this.videoElement.current.currentTime
          //  console.log("detectEmotions after", currentTime)
            if(!countingDown){
                let trackerItem = handleDetectionResult(detectionsWithExpressions, currentTime)
               // console.log("handleDetectionResult", currentTime)



                this.setPlayerState(trackerItem.result, currentTime, trackerItem.result)
                if (this.props.videoAnalyzeForLiveStream) {
                    this.saveResults({
                        emotions: trackerItem.emotions,
                        currentTime,
                        result: trackerItem.result,
                        faceMeshResult
                    })
                }

            }


            if (this.shouldDetectEmotions()) {
                this.detectTimeOut = setTimeout(() => this.detectEmotions(), detectionInterval)
            }
        } catch (e) {
            console.log("error", e)
        }
    }

    setPlayerState = (emotions, currentTime, detectionsArray) => {
        this.pushToOverTime(emotions, currentTime)
        this.setState({detectionsArray})
    }

    saveResults = ({currentTime, result, emotions, faceMeshResult}) => {
        const {setVideoTracker, tracker} = this.props

        let updatedTracker = _.cloneDeep(tracker)
        let previewTracker = _.cloneDeep(this.state.previewTracker)

        let trackerItem = {
            currentTime,
            result,
            emotions
        };

        // check if trackerItem already exists
        let index = findIndex(updatedTracker, {currentTime: currentTime})
        if(index !== -1){
            console.log("duplicate item at ", currentTime)
            return
        }

        updatedTracker.push(trackerItem)

        setVideoTracker(updatedTracker)

        let newTrackerItem = {
            ...trackerItem,
            faceMeshResult: faceMeshResult
        };
        previewTracker.push(newTrackerItem)
        this.setState({previewTracker: previewTracker})

        //console.log("push to tracker at ", currentTime, faceMeshResult)

    }

    addSuffix = (key, suffix) => {
        if(suffix){
            return key + "_" + suffix
        }
        return key
    }

    getOTBlendshapesFromTrackerItem = (item, suffix = null) => {
        let blendshapes = {
            jawOpen: null,
            mouthPucker: null,
            browsDown: null,
            browsOuterUp: null,
            eyesBlink: null,
            eyesSquint: null,
            eyesLookDown: null,
            eyesLookRight: null,
            eyesLookLeft: null,
            eyesLookHorizontal: null,
            eyesLookVertical: null,
            eyesLookUp: null,
            mouthUpperUp: null,
        }

        if (item && item.faceBlendshapes) {
            for (const key in item.faceBlendshapes) {
                blendshapes[key] = normalizeValue(item.faceBlendshapes[key])
            }
        }
        if(suffix){
            for (const key in blendshapes) {
                blendshapes[this.addSuffix(key, suffix)] = blendshapes[key]
                delete blendshapes[key]
            }
        }

        // calaculate eyesLookHorizontal and eyesLookVertical
        if (blendshapes.eyesLookRight && blendshapes.eyesLookLeft) {
            blendshapes.eyesLookHorizontal = (blendshapes.eyesLookRight - blendshapes.eyesLookLeft) / 2
        }

        if (blendshapes.eyesLookUp && blendshapes.eyesLookDown) {
            blendshapes.eyesLookVertical = (blendshapes.eyesLookUp - blendshapes.eyesLookDown) / 2
        }

        return blendshapes
    }

    pushToOverTime = (data, currentTime, state) => {
        //console.log("pushToOverTime", data, currentTime)
        const {overtime, overtimeById} = state ? state : this.state
        let updatedOvertime = _.cloneDeep(overtime)
        const clonedOvertimeById = _.cloneDeep(overtimeById)

        let index = format(currentTime);

        let item = {}
        let generalItem = {}

        for (let i = 0; i < data.length; i++) {
            // console.log("pushToOverTime", i, data[i])
            let d = data[i]

            let yawnRatio = d.faceBlendshapes ? Math.round(d.faceBlendshapes.jawOpen * 100) : null;
            let eyebrowEyeRatio = d.faceBlendshapes ? calculateEyebrowDirection(d.faceBlendshapes.browsOuterUp, d.faceBlendshapes.browsDown) : null;
            let frownScore = d.frown ? (d.frown.frownScore) - 200 : null
            let pitchAngle = d.headPose ? d.headPose.pitch : null
            let noseEyebrowRatio = d.frown ? d.frown.noseEyebrowRatio : null;
            let yawAngle = (d.headPose ? d.headPose.yaw : null);
            let eyesBlink = d.faceBlendshapes ? d.faceBlendshapes.eyesBlink : null;

            let yawFallback = d.headAnglesFallback ? d.headAnglesFallback.yaw : null;
            let pitchFallback = d.headAnglesFallback ? d.headAnglesFallback.pitch : null;
            let rollFallback = d.headAnglesFallback ? d.headAnglesFallback.roll : null;


            if (yawnRatio > 100) {
                yawnRatio = 100
            }

            if (frownScore > 100) {
                frownScore = 100
            } else if (frownScore < 0) {
                frownScore = 0
            }

            if (noseEyebrowRatio > 350) {
                noseEyebrowRatio = 350
            } else if (noseEyebrowRatio < 300) {
                noseEyebrowRatio = 300
            }

            let blendshapes = this.getOTBlendshapesFromTrackerItem(d, d.id)

            let headAngles = {yaw: yawAngle, pitch: pitchAngle, roll: (d.headPose ? d.headPose.roll : null)}
            let filterEmotions = d.toFilter || false
            /*  if(!headAngles.yaw){
                 filterEmotions = true
             }
 */
           /* let validHeadAngles = !headAngles.yaw ? false : headFacingCameraEmotionFilter(headAngles);
            if (HEAD_ANGLE_EMOTION_FILTER_ACTIVE && !validHeadAngles) {
                console.log("invalid headAngles:", headAngles)
                filterEmotions = true
                // continue
            }*/



            item = {
                ...item,
                index,
                timeSpent: currentTime * 1000,
                id: d.id,
                [`mood_${d.id}`]: !filterEmotions ? normalizeValue(d.valence) : null,
                [`energy_${d.id}`]: !filterEmotions ? normalizeValue(d.energy) : null,
                [`wellbeing_${d.id}`]: !filterEmotions ? normalizeValue(d.wellbeing) : null,
                [`engagement_${d.id}`]: !filterEmotions ? normalizeValue(d.engagement) : null,
                [`interest_${d.id}`]: !filterEmotions ? normalizeValue(d.interest) : null,
                [`stress_${d.id}`]: !filterEmotions ? normalizeValue(d.stress) : null,
                [`closedEyes_${d.id}`]: eyesBlink !== null ? (eyesBlink) * 100 : null ,
                [`yawn_${d.id}`]:  normalizeValue(d.yawn),
                [`yawnRatio_${d.id}`]: yawnRatio ,
                [`mask_${d.id}`]:  normalizeValue(d.mask),
                [`noMask_${d.id}`]:  normalizeValue(d.noMask),
                [`closedLeftEye_${d.id}`]:  normalizeValue(d.closedLeftEye),
                [`openLeftEye_${d.id}`]:  normalizeValue(d.openLeftEye),
                [`closedRightEye_${d.id}`]:  normalizeValue(d.closedRightEye),
                [`openRightEye_${d.id}`]:  normalizeValue(d.openRightEye),
                [`openEyes_${d.id}`]:  eyesBlink !== null ? (1 - eyesBlink) * 100 : null,
                [`yawAngle_${d.id}`]: yawAngle,
                [`pitchAngle_${d.id}`]: pitchAngle,
                [`eyebrowEyeRatio_${d.id}`]: !filterEmotions ? eyebrowEyeRatio : null,
                [`frownScore_${d.id}`]: !filterEmotions ? frownScore : null,
                [`noseEyebrowRatio_${d.id}`]: !filterEmotions ? noseEyebrowRatio : null,
                [`eyesBlink_${d.id}`]: !filterEmotions ? eyesBlink : null,
                [`yawFallback_${d.id}`]: yawFallback,
                [`pitchFallback_${d.id}`]: pitchFallback,
                [`rollFallback_${d.id}`]: rollFallback,
                ...blendshapes
            }

            generalItem = {
                ...item,
                index,
                timeSpent: currentTime * 1000,
                id: d.id,
                [`mood`]: !filterEmotions ? normalizeValue(d.valence) : null,
                [`energy`]: !filterEmotions ? normalizeValue(d.energy) : null,
                [`wellbeing`]: !filterEmotions ? normalizeValue(d.wellbeing) : null,
                [`engagement`]: !filterEmotions ? normalizeValue(d.engagement) : null,
                [`interes}`]: !filterEmotions ? normalizeValue(d.interest) : null,
                [`stress`]: !filterEmotions ? normalizeValue(d.stress) : null,
                [`closedEyes`]:  (eyesBlink) * 100,
                [`yawn`]:  normalizeValue(d.yawn),
                [`yawnRatio`]:  yawnRatio,
                [`mask`]:  normalizeValue(d.mask),
                [`noMask`]:  normalizeValue(d.noMask),
                [`closedLeftEye`]:  normalizeValue(d.closedLeftEye),
                [`openLeftEye`]:  normalizeValue(d.openLeftEye),
                [`closedRightEye`]:  normalizeValue(d.closedRightEye),
                [`openRightEye`]:  normalizeValue(d.openRightEye),
                [`openEyes`]:  (1 - eyesBlink) * 100,
                [`yawAngle`]: yawAngle,
                [`pitchAngle`]: pitchAngle,
                [`eyebrowEyeRatio`]:  eyebrowEyeRatio,
                [`frownScore`]:  frownScore,
                [`noseEyebrowRatio`]:  noseEyebrowRatio,
                [`eyesBlink`]:  eyesBlink,
                frown: {
                    ...item.frown,
                    eyebrowEyeRatio,
                    frownScore,
                    noseEyebrowRatio,
                },
                yawFallback,
                pitchFallback,
                rollFallback
            }


            if (overtimeById[d.id]) {
                clonedOvertimeById[d.id].push(generalItem)
            } else {
                clonedOvertimeById[d.id] = [generalItem]
            }


            let blink = detectBlink(clonedOvertimeById[d.id], d.id)
            //console.log("blink", blink)
            item[`blink_${d.id}`] = blink
            clonedOvertimeById[d.id][clonedOvertimeById[d.id].length - 1].blink = blink
            clonedOvertimeById[d.id][clonedOvertimeById[d.id].length - 1][`blink_${d.id}`] = blink


            let blinksPM = calculateFrequencyPerMinute(clonedOvertimeById[d.id], `blink_${d.id}`, BLINK_FREQUENCY_DURATION_SECONDS, BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS)
            //console.log("blinksPM", blinksPM)
            // console.log("blink", blink)
            // console.log("blinksPM", blinksPM)
            item[`blinksPM_${d.id}`] = blinksPM
            clonedOvertimeById[d.id][clonedOvertimeById[d.id].length - 1].blinksPM = blinksPM


            // console.log("overtime item", generalItem.eyebrowEyeRatio)


            /* let blink = detectBlink(state.overtimeById[d.id], d.id)
             item[`blink_${d.id}`] = blink

             let blinksPM = calculateFrequencyPerMinute(state.overtimeById[d.id], `blink_${d.id}`, BLINK_FREQUENCY_DURATION_SECONDS, BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS)

             item[`blinksPM_${d.id}`] = blinksPM*/
            if (!state) {
                this.setState({overtimeById: clonedOvertimeById})
            }

        }
        updatedOvertime.push(item)

        // console.log("updatedOvertime", item)

        if (!state) {
            this.setState({overtime: updatedOvertime})
        } else {
            return {
                overtime: updatedOvertime,
                overtimeById: clonedOvertimeById
            }
        }

    }

    renderDetectionSquare = (height, width, vidCurrTime, data) => {

        const {detectionsArray} = this.state
        const {previewMode, videoAnalyzeForLiveStream} = this.props
        let result = _.cloneDeep(detectionsArray)
        // const result = data.filter(d => d.currentTime === vidCurrTime) //from state - video upload processed in server

      //  console.log("renderDetectionSquare", result)
        //console.log("renderDetectionSquare", Date.now(), videoAnalyzeForLiveStream)
        let map = result.map(r => { //d
                // let r = (d.result[0])
                let dominantEmotion = this.getDominantEmotion(r)
                let emphasizeDetection = true

            let frame = performance.now()

            if(!r.stressLevel){
                return null;
            }

            let boxOnly = true
            if((previewMode || videoAnalyzeForLiveStream) && r.stress !== null){
                boxOnly = false
            }

                return <DetectionSquare
                    id={r.id}
                    frame={frame}
                    emphasize={emphasizeDetection}
                    percentage={dominantEmotion.value}
                    label={getString(dominantEmotion.label)}
                    progressColor={dominantEmotion.color}
                    top={(r.detection._box._y / (r.detection._imageDims._height / height)) - 15}
                    left={r.detection._box._x / (r.detection._imageDims._width / width) - 10}
                    width={r.detection._box._width / (r.detection._imageDims._width / width) + 15}
                    height={(r.detection._box._height / (r.detection._imageDims._height / height)) + 5}
                    extendedDetectionSquare={true}
                    stressLevel={r.stressLevel}
                    interestLevel={r.interestLevel}
                    engagementLevel={r.engagementLevel}
                    wellbeingLevel={r.wellbeingLevel}
                    boxOnly={boxOnly}
                />
            }
        );

        // console.log("map", map)
        return map
    }

    getDominantEmotion = (detection) => {
        const expressionsArr = []

        for (const expression in detection.expressions) {
            if (typeof detection.expressions[expression] === "number") {
                const color = this.detectEmotionColor(expression)
                const value = Math.round(detection.expressions[expression] * 100)
                expressionsArr.push({label: expression, color, value})
            }
        }

        //console.log("expressionsArr", expressionsArr)

        if(expressionsArr.length === 0){
            return {label: "", color: "#fff", value: 100}
        }
        const maxObject = expressionsArr.reduce(function (prev, current) {
            return (prev.value > current.value) ? prev : current
        })

        return maxObject
    }

    detectEmotionColor = (expression) => {
        switch (expression) {
            case 'happy':
                return "#FFBD58"
            case 'neutral':
                return "#6FCF97"
            case 'sad':
                return "#EB5757"
            case 'angry':
                return "#000000"
            case 'disgusted':
                return "#828282"
            case 'fearful':
                return "#BB6BD9"
            case 'surprised':
                return "#56CCF2"
            default:
                return "#6FCF97"
        }
    }

    renderOvertimeChart = () => {
        const {selectedButtons, overtime, previewOverTime, showFaceLandmarks} = this.state
        const {faceIds, selectedIds, previewMode, faceIdCount, selectId, videoAnalyzeForLiveStream} = this.props

        if (!previewMode) {
           // if(!videoAnalyzeForLiveStream){
               return null;
           // }
        }

        let modified = previewMode ? _.cloneDeep(previewOverTime) : _.cloneDeep(overtime)

        //console.log("renderOvertimeChart", modified)

        return <SoloOverTimeChart
            overtime={modified}
            faceIds={faceIds}
            faceIdCount={faceIdCount}
            selectedIds={selectedIds}
            showBrush={false}
            onBrushChange={()=>{}}
            toggleFaceLandmarks={this.toggleFaceLandmarks}
            resetOverTime={null}
            selectId={selectId}
            showFaceLandmarks={showFaceLandmarks}
            isJP={translations.selectedLocale === "ja"}
        />

    }

    toggleFaceLandmarks = () => {
        const {showFaceLandmarks} = this.state
        this.setState({showFaceLandmarks: !showFaceLandmarks})
    }

    renderFooter = () => {
        let percentage = 0
        const video = document.getElementById('vid');
        const {backgroundProcessingPercentage} = this.state
        const {backgroundProcessing} = this.props
        if (backgroundProcessingPercentage) {
            percentage = backgroundProcessingPercentage;
        } else if (video) {
            let videoDuration = video.duration
            let currentTime = this.videoElement.current && this.videoElement.current.currentTime
            if (!isNaN(videoDuration)) {
                percentage = (currentTime / videoDuration) * 100
            }
        }

        let title = "";
        if (this.props.videoAnalyzeForLiveStream) {
            percentage = 100
            title = getString("analyzing")
        } else if (percentage === 0) {
            title = getString("start_video_analyze")
        } else if (backgroundProcessing) {
            title = `${getString("processing_video")} ${backgroundProcessingPercentage}%`
        }

        if (this.props.previewMode) {
            title = getString("preview")
        }

        return (<Footer>
            <LoaderTitle>
                {title}
            </LoaderTitle>
            <Loader progress={`${(this.progressLimit(0, percentage, 100) / 100) * 100}%`}
                    style={this.props.previewMode ? {backgroundColor: colors.lipstick} : null}/>
        </Footer>)
    }

    progressLimit = (min, value, max) => {
        return Math.min(Math.max(min, value), max);
    }

    updateInput = (input) => {
        this.setState({input})
    }

    streamStarted = async () => {
        const {input, streamReady} = this.state
        console.log("streamStarted", input)
        if(streamReady){
            return
        }
        let currentTime = this.videoElement.current && this.videoElement.current.currentTime
        console.log("warmupOffset", currentTime)
        this.setState({streamReady: true, countingDown: false, warmupOffset: currentTime})
       // this.detectEmotions()
       // this.startCountDown()

    }

    warmup = (input) => {
        console.log("warmup")
        this.setState({countingDown: true, input},()=>{
                this.detectEmotions()
            })
    }

    previewStarted = async () => {
        const {tracker} = this.props

        console.log("previewStarted", tracker)

    }

    updateBackgroundProcessing = (currentTime, duration) => {
        this.setState({
            backgroundProcessingPercentage: duration ? Math.round((currentTime / duration) * 100) : 0
        })
    }

    render() {

        const {videoSrc, showFaceLandmarks, streamReady, countDown, countingDown} = this.state
        const {setVideoTracker, tracker, height, videoAnalyzeForLiveStream, previewMode} = this.props

        let imageWidth, imageHeight, detectionSquareTop, detectionSquareLeft

        if (this.videoElement.current) {
            imageWidth = this.videoElement.current.offsetWidth
            imageHeight = this.videoElement.current.offsetHeight
            detectionSquareTop = this.videoElement.current.offsetTop
            detectionSquareLeft = this.videoElement.current.offsetLeft
        }
        let videoMaxHeight = "100%"
        if (height) {
            videoMaxHeight = height * .75 - 20 - 45 - 60
        }

        // let canvasLeft = 0;
        let canvasTop = 0;
        let vidDims = {}

        let vid = document.getElementById('myVideo')
        if (videoAnalyzeForLiveStream && !previewMode) {
            vid = document.getElementById('videoElement')
        }
        if (vid) {
            let {width, height} = videoDimensions(vid)
            //  canvasLeft = (vid.offsetWidth - width) / 2
            canvasTop = (vid.offsetHeight - height) / 2
            vidDims = {width, height, offsetWidth: vid.offsetWidth, offsetHeight: vid.offsetHeight}
        }

      //  console.log("previewMode", previewMode)

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: "100%",
                // justifyContent: "space-evenly",
                paddingBottom: 60
            }}>
                {!streamReady && videoAnalyzeForLiveStream && !previewMode && !countingDown && (
                    <img style={{height: 100, width: 100, marginTop: "33%", marginLeft: "43%"}} src={getAsset("loader.svg")} alt={"loading..."}/>
                )}
                <VideoContainer>

                    {videoAnalyzeForLiveStream && !previewMode ? null : videoSrc && <video
                        id="vid"
                        style={{
                            maxWidth: '100%',
                            maxHeight: videoMaxHeight,
                        }}
                        controls={previewMode}
                        autoPlay={false}
                        src={videoSrc}
                        ref={this.videoElement}
                        crossOrigin='anonymous'
                    />}
                    {videoAnalyzeForLiveStream && !previewMode && (
                        <VideoStream
                            streamSuccessHandler={this.streamStarted}
                            width={"100%"}
                            maxHeight={videoMaxHeight}
                            updateInput={this.updateInput}
                            innerRef={this.videoElement}
                            onRecordUpdate={(recordedChunks) => {
                                if(countingDown){
                                    return;
                                }
                                console.log("recordedChunks", recordedChunks)
                                let recordedVideoChunks = this.state.recordedVideoChunks.concat(recordedChunks);
                                this.onRecordingComplete(recordedVideoChunks)

                            }}
                            shouldRecord={true}
                            countDown={true}
                            warmup={this.warmup}
                        />
                    )}
                    <canvas id={"canvas-overlay"} style={{
                        position: "absolute",
                        top: canvasTop,
                        width: vidDims.width,
                        height: vidDims.height - 100,
                        zIndex: 99999,
                        visibility: showFaceLandmarks ? "visible" : "hidden",
                        pointerEvents: "none"
                    }}/>
                    <div style={{position: 'absolute', top: detectionSquareTop, left: detectionSquareLeft}}>
                        {this.renderDetectionSquare(imageHeight, imageWidth)}
                    </div>

                </VideoContainer>

                {this.renderOvertimeChart()}
                {this.renderFooter()}

                {!previewMode && (
                    <VideoFaceDetector
                        interval={200}
                        src={videoSrc}
                        onFinishDetection={() => {
                            console.log("finish detection", tracker)
                            this.props.setBackgroundProcessing(false)
                        }}
                        options={this.state.options}
                        saveResults={this.saveResults}
                        videoStarted={() => {
                            this.props.setBackgroundProcessing(true)
                        }}
                        onProgress={({currentTime, duration}) => {
                            this.updateBackgroundProcessing(currentTime, duration)
                        }}

                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = ({video, house, faceId}) => ({
    tracker: video.tracker,
    currentHouse: house.currentHouse,
    faceIds: faceId.faceIds,
    faceIdCount: faceId.faceIdCount,
    selectedIds: faceId.selectedIds
});

const mapDispatchToProps = {
    setVideoTracker,
    addFaceId,
    selectId,
    updateFaceIdCount,
    resetFaceId,
};

Video.propTypes = {
    previewMode: PropTypes.bool,
    detectionInterval: PropTypes.number,
}

Video.defaultProps = {
    previewMode: false,
    detectionInterval: 500,
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);

const VideoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 75%;
    position: relative;
`

const OverTimeContainer = styled.div`
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
`

const OverTimeButton = styled.button`
    background: #909090;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    // padding-left: 10px;
    // padding-right: 10px;

    font-family: Open Sans;
    font-weight: 900;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    width: 92px;
    height: 20px;

    color: #FFFFFF;
    cursor: pointer;
    border: 0;
    outline: 0;
`

const LoaderTitle = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000247;
    text-transform: capitalize;
`

const Loader = styled.div`
    height: 40px;
    width: ${props => props.progress};
    background: #536EEC;
    transition: width 900ms;
    border-bottom-right-radius: 9px;
`

const Footer = styled.div`
    width: 100.3%;
    bottom: 0;
    left: 0;
    height: 40px;
    margin: -1px;
    position: absolute;
`
